.panel-right {
    height: 100%;
    width: 0;
    position: fixed;
    right: 0;
    top: 0;
    display: none;
    background-color: white;
    box-shadow: 5px 1px 20px 1px rgba(80, 80, 80, 1);
    overflow-x: hidden;
    /* -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; */
    /* transform: translateX(0%);
    transition: transform 1s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
    animation: linear move-in 200ms;
}

.panel-right.show {
    display: block;
    width: 80%;
    z-index: 100;
    background-color: #f2f3f8;
    /* animation: linear move-in 1000ms; */
    /* animation-name: move-out;
    animation-duration: 500ms; */
}

.kt-aside--minimize .panel-right.show{
    width: 90%;
}

.close-btn-side-details {
    position: absolute;
    top: 0px;
    right: 0px;
}

.panel-right.closing {
    animation: linear move-out 200ms;
}
.btn-close {
    background: #f2f3f7;
    color: #959cb6;
    padding: 4px 2px 4px 8px;
}

.btn-close:hover {
    background: #1a73e9;
    color: #ffffff;
}

button {
    margin: auto;
}

@keyframes move-out {
    0% {transform: translateX(0);}
    100% {transform: translateX(450px);}
}

@keyframes move-in {
    0% {transform: translateX(450px);}
    100% {transform: translateX(0);}
}