.author-container {
    padding-top: unset;
    height: auto;
    width: 100%;
    /* box-shadow: 2px rgba(100, 100, 100, 0.5); */
    padding: 10px;
    /* border-radius: 10px; */
    text-align: left;
    margin-bottom: 10px;
  }
  
  .add-fm-btn {
    width: auto !important;
    margin-bottom: 10px;
    /* white-space: nowrap; */
  }
  
  /* .author-container input {
    margin-top: 10px;
  } */
  
  .add-card {
    background-color: #1a73e9;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  
  .btn-primary,
  .btn-brand {
    background-color: #1a73e9;
    display: block;
  }
  
  .btn-primary:disabled {
    background-color: #1a375c;
  }
  
  .btn-primary:disabled:hover {
    background-color: #1a375c;
  }
  
  .add-card:hover,
  .btn-primary:hover,
  .btn-brand:hover {
    background-color: #0962d8;
  }
  
  .card-title {
    font-weight: bold;
    font-size: 1.15em;
    margin-bottom: 10px;
    display: block;
  }
  
  .btn-red {
    background-color: red;
    color: white;
  }
  
  .btn-red:hover {
    color: white;
  }
  
  .delete-icon {
    width: 20px;
    float: right;
    cursor: pointer;
  }
  
  .add-card span {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1.5em;
    user-select: none;
  }
  
  .col-md-4 {
    margin-bottom: 20px;
  }
  
  .add-card .icon {
    font-size: 10em;
  }
  
  .bottom-btn-container {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  
  
  /* ///AddBacklink css */
  
  .backlink-container {
    padding-top: unset;
    height: auto;
    width: 100%;
    /* box-shadow: 2px rgba(100, 100, 100, 0.5); */
    padding: 10px;
    /* border-radius: 10px; */
    text-align: left;
    margin-bottom: 10px;
  }
  
  .add-fm-btn {
    width: auto !important;
    margin-bottom: 10px;
    /* white-space: nowrap; */
  }
  
  .backlink-container input {
    margin-top: 10px;
  }
  
  .add-card {
    background-color: #1a73e9;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  
  .btn-primary,
  .btn-brand {
    background-color: #1a73e9;
    display: block;
  }
  
  .btn-primary:disabled {
    background-color: #1a375c;
  }
  
  .btn-primary:disabled:hover {
    background-color: #1a375c;
  }
  
  .add-card:hover,
  .btn-primary:hover,
  .btn-brand:hover {
    background-color: #0962d8;
  }
  
  .card-title {
    font-weight: bold;
    font-size: 1.15em;
    margin-bottom: 10px;
    display: block;
  }
  
  .btn-red {
    background-color: red;
    color: white;
  }
  
  .btn-red:hover {
    color: white;
  }
  
  .delete-icon {
    width: 20px;
    float: right;
    cursor: pointer;
  }
  
  .add-card span {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1.5em;
    user-select: none;
  }
  
  .col-md-4 {
    margin-bottom: 20px;
  }
  
  .add-card .icon {
    font-size: 10em;
  }
  
  .bottom-btn-container {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  